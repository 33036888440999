footer {
  margin-top: 2rem;
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 1.5rem 1rem;
  font-size: 0.9rem;
  font-weight: 300;
}
footer section {
  margin-top: 1.3rem;
}
footer li {
  list-style: none;
  padding: 0.5rem 0;
  cursor: pointer;
}
footer a {
  color: var(--white-color);
  text-decoration: none;
  transition: 0.2s ease all;
  display: flex;
  align-items: center;
}
footer a svg {
  margin-right: 0.3rem;
  font-size: 1.2rem;
}
footer a:hover {
  color: var(--text-color);
}
footer h5 {
  color: var(--text-color);
  font-size: 0.9rem;
  padding: 0.5rem 0;
  font-family: var(--header-font);
}
.footer-logo img {
  width: 5rem;
  margin-bottom: 1rem;
}
.footer-logo p {
  line-height: 1.5rem;
}
.sub-footer {
  font-size: 0.7rem;
  text-align: center;
  color: var(--text-color);
  margin-top: 2rem;
}

.footer-content {
  border-bottom: 1px inset var(--white-color);
  padding-bottom: 2rem;
}

@media only screen and (min-width: 879px) {
  footer {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (min-width: 1024px) {
  .footer-content {
    display: flex;
    justify-content: space-between;
  }

  .footer-logo {
    flex: 0.7;
  }
}

@media only screen and (min-width: 1440px) {
  .footer-resp-container {
    width: 1390px;
    margin: 0 auto;
    padding: 0 2rem;
  }
}
