* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: var(--text-font);
  scroll-behavior: smooth;
  text-decoration: none;
}
body {
  background-color: var(--secondary-color);
  position: relative;
}

a,
button {
  cursor: pointer;
  transition: 0.2s ease all;
}

button:hover {
  transform: scale(1.05);
}

:root {
  --primary-color: #41b0cd;
  --white-color: #fff;
  --text-color: #07294a;
  --secondary-text-color: #455964;
  --secondary-color: #ededed;
  --text-font: 'Montserrat', sans-serif;
  --header-font: 'PT Sans', sans-serif;
}
