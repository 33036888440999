.hero-section_container {
  padding-top: 6rem;
  width: 100%;
  color: var(--text-color);
}
.hero-img_container {
  width: 100%;
  padding: 1rem;
}
.hero-img_container img {
  max-width: 100%;
  object-fit: contain;
  margin-bottom: 1.5rem;
}
.hero-text_container {
  padding: 0 1rem;
}
.hero-text_container h1 {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 1rem;
  line-height: 2.5rem;
  font-family: var(--header-font);
  margin-top: 1.5rem;
}
.hero-text_container p {
  line-height: 1.5rem;
  padding-bottom: 1rem;
  font-size: 0.93rem;
  font-weight: 300;
  color: var(--secondary-text-color);
}

.hero-text_container button {
  background-color: var(--primary-color);
  border: none;
  padding: 1.5rem 1.5rem;
  text-transform: uppercase;
  border-radius: 5px;
  margin-top: 2rem;
  color: var(--white-color);
}

.stats-section {
  margin-top: 3rem;
  display: flex;
  text-align: center;
}
.stats {
  padding-right: 2rem;
}
.stats-section p {
  color: initial;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: var(--header-font);
  line-height: 0.4rem;
  color: var(--text-color);
}
.stats-section span {
  font-size: 0.7rem;
  color: var(--secondary-text-color);
  font-weight: 300;
}

/* media queries */
@media only screen and (min-width: 879px) {
  .hero-section_container {
    flex-direction: row-reverse;
    padding: 3rem 2rem;
    background-color: var(--secondary-color);
    padding-top: 9rem;
  }

  .hero-text_container {
    padding: 0;
  }

  .hero-img_container {
    display: flex;
    padding: 0;
  }

  .stats-section {
    justify-content: unset;
    padding-top: 2rem;
  }
  .stats {
    padding-right: 1.5rem;
  }
  .stats p {
    font-size: 2rem;
  }
}
@media only screen and (min-width: 1024px) {
  .hero-content-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .hero-img_container {
    grid-column: 2;
  }

  .hero-text_container {
    grid-column: 1;
    grid-row: 1;
  }

  .hero-text_container h1 {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .hero-img_container img {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .hero-content-container {
    padding: 2rem;
    padding-bottom: 0;
    width: 1390px;
    margin: 0 auto;
  }
}
