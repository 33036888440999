.dashboard-container {
  padding: 1rem;
  padding-top: 8rem;
  color: var(--text-color);
}

.user-avatar img {
  width: 4rem;
  border-radius: 50%;
  margin-bottom: 0.3rem;
}

.user-profile p {
  font-size: 1rem;
  font-weight: 500;
}

.user-profile span {
  font-size: 0.7rem;
  color: var(--secondary-text-color);
  display: block;
  padding: 0.5rem 0;
}

.other-details {
  background-color: var(--primary-color);
  padding: 1rem;
  margin-top: 2rem;
  border-radius: 5px;
}

.other-details p {
  padding: 0.7rem;
  font-weight: 500;
  font-size: 0.9rem;
}

.other-details strong {
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 400;
  color: var(--white-color);
}

.update-btn_container {
  display: flex;
  justify-content: flex-end;
  padding-top: 0.5rem;
}

.update-btn {
  background-color: var(--white-color);
  border: none;
  border-radius: 5px;
  padding: 0.7rem 0.5rem;
  cursor: pointer;
  font-size: 0.7rem;
  font-weight: 500;
}

/* update details form section */
.update-form {
  width: 100%;
}
.btn {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
}
.btn button {
  width: 50%;
  border: none;
  padding: 1rem;
  border-radius: 5px;
}
.cancel-btn {
  background-color: #d82148;
  color: var(--white-color);
}
.update-form form {
  height: 60%;
}
.update-form input {
  font-size: 1rem;
  font-weight: 600;
  font-family: var(--header-font);
}
.form-input textarea {
  width: 100%;
  height: 5rem;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  font-family: var(--header-font);
  outline: none;
  border-radius: 5px;
}
.form-input textarea:focus {
  border: 2px solid var(--primary-color);
}

/* request delivery section */

.request-delivery h1 {
  font-size: 1.4rem;
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 2rem;
  position: relative;
  margin-bottom: 3rem;
}

.request-delivery h1::after {
  content: '';
  position: absolute;
  height: 3px;
  width: 10rem;
  background-color: var(--primary-color);
  left: 50%;
  transform: translateX(-50%);
  bottom: 1.5rem;
  border-radius: 10px;
}

.deliveryInfo {
  margin-top: 1rem;
  position: relative;
}

.deliveryInfo h4 {
  margin-top: 3rem;
  padding-bottom: 1rem;
  color: var(--primary-color);
}

.deliveryInfo h3 {
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.deliveryInfo p {
  font-weight: 600;
  padding: 0.5rem 0;
}

.deliveryInfo span {
  font-weight: 500;
  font-size: 0.9rem;
}

.form-field {
  padding-top: 1rem;
}

.checkmark-field section {
  padding-top: 0.5rem;
  font-size: 0.9rem;
}

.checkmark-field input {
  margin-right: 0.5rem;
  cursor: pointer;
}

.form-field input,
.form-field select,
.form-field textarea {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  background-color: rgba(128, 128, 128, 0.397);
  outline: none;
  border-radius: 5px;
}

.form-field input:focus,
.form-field select:focus,
.form-field textarea:focus {
  border: 2px solid var(--primary-color);
}

.form-field input,
.form-field select {
  height: 3rem;
}

.form-field label {
  display: block;
  padding-bottom: 0.2rem;
}

.request-delivery button {
  padding: 1rem;
  border: none;
  margin-top: 3.5rem;
  color: var(--white-color);
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  width: 8rem;
}

.request-delivery-btn .request-cancel-btn {
  margin-right: 2rem;
  background-color: #d82148;
}
.request-delivery-btn .request-submit-btn {
  background-color: green;
}

.clinetInfo {
  font-size: 0.9rem;
  font-weight: 300;
}
.clinetInfo label {
  margin-bottom: 0.3rem;
}
.clinetInfo span {
  color: var(--primary-color);
  font-weight: 500;
  font-size: 1rem;
  display: block;
  padding-bottom: 0.3rem;
}

.clinetInfo textarea {
  height: 5rem;
}

.hidden-input {
  display: none;
}

.deliveryInfo .spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: #000;
  opacity: 0.5;
}

.deliveryInfo .success img {
  background-color: #000;
  opacity: 0.5;
}

.deliveryInfo .success h2 {
  padding-top: 1rem;
}

.success button {
  background-color: var(--primary-color);
  width: 60%;
  margin-top: 1rem;
}

.max-package {
  color: #d82148;
}

/* media queries */
@media only screen and (min-width: 879px) {
  .request-delivery {
    width: 750px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1440px) {
  .user-profile {
    width: 1390px;
    margin: 0 auto;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
