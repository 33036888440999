header {
  padding: 1rem 1rem;
  color: var(--white-color);
  position: fixed;
  width: 100%;
  background-color: var(--primary-color);
  box-shadow: 0px 2px 10px rgba(119, 116, 116, 0.25);
  -webkit-box-shadow: 0px 2px 10px rgba(119, 116, 116, 0.25);
  -moz-box-shadow: 0px 2px 10px rgba(119, 116, 116, 0.25);
  z-index: 5;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
nav {
  display: flex;
  align-items: center;
}
.logo-container {
  display: flex;
  align-items: center;
}
.logo-container img {
  width: 5rem;
}
nav ul {
  position: absolute;
  left: 0;
  top: 4.7rem;
  transition: 0.5s ease all;
  background-color: var(--primary-color);
  height: 100vh;
  width: 100%;
  text-align: center;
  padding-top: 3rem;
  transform: translateX(-100vw);
}
.toggle-class {
  transform: translateX(0vw);
}
nav li {
  padding: 1rem;
  text-transform: uppercase;
}
nav svg {
  font-size: 1.7rem;
  color: #fff;
}
nav a {
  text-decoration: none;
  color: var(--white-color);
  transition: 0.2s ease all;
}

nav a:hover {
  color: var(--text-color);
}

.delivery_btn {
  background-color: #1a86a1;
}
.delivery_btn:hover {
  transform: scale(1.1);
}

/* media queries */
@media only screen and (min-width: 879px) {
  nav ul {
    transform: translateX(0vw);
    position: unset;
    display: flex;
    list-style: none;
    background-color: unset;
    height: unset;
    padding-top: 0;
  }
  .nav-links,
  .delivery_btn {
    text-transform: initial;
    font-size: 0.9rem;
    cursor: pointer;
    padding: 0.8rem 1rem;
    border-radius: 5px;
    transition: 0.2s ease all;
    position: relative;
  }

  .nav-links:hover::after {
    content: '';
    background-color: var(--primary-color);
    width: 50%;
    height: 2.5px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  nav svg {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  header {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (min-width: 1440px) {
  .header-container {
    width: 1400px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
