.locations-container {
  padding: 1rem;
  padding-top: 7rem;
}

.locations-container h1 {
  font-size: 1rem;
  text-align: center;
  font-weight: 300;
  padding-top: 1rem;
  margin-bottom: 4rem;
}
.locations-container p {
  padding: 1rem 0.5rem;
  background-color: var(--primary-color);
  margin: 1rem 0;
  color: var(--white-color);
  border-radius: 5px;
}
.search-box {
  width: 100%;
  position: relative;
  margin: 1rem 0;
}
.search-box input {
  width: 100%;
  height: 3rem;
  border: 1px solid var(--primary-color);
  padding: 0 1rem;
  outline: none;
  border-radius: 5px;
}
.search-box input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.search-box svg {
  position: absolute;
  font-size: 1.2rem;
  color: var(--secondary-text-color);
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.spinner {
  display: flex;
  justify-content: center;
}

.spinner img {
  width: 5rem;
}

/* media query */

@media only screen and (min-width: 768px) {
  .all-locations {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 1%;
  }
  .all-locations p {
    width: 30%;
  }

  .search-box {
    width: 30rem;
    margin: 2rem auto;
  }
}

@media only screen and (min-width: 1440px) {
  .locations-container {
    width: 1390px;
    margin: 0 auto;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
