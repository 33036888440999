.signup-form,
.login-form {
  padding: 1rem;
  width: 100%;
  background-color: var(--white-color);
  border-radius: 5px;
  padding-bottom: 3rem;
  color: var(--secondary-text-color);
  box-shadow: 0px -4px 16px rgba(107, 124, 243, 0.06);
}

.login-form-container,
.signup-form-container {
  padding: 0 0.5rem;
}

.signup-form-container {
  padding-bottom: 3rem;
}

.login-form-container {
  height: 100vh;
}

.onboard-logo-container {
  padding: 0.9rem 0;
}

.onboard-logo-container img {
  margin: 0 auto;
  display: block;
  width: 6rem;
}

.form-title {
  border-bottom: 1px solid var(--secondary-color);
  text-align: center;
  padding: 0.5rem 0;
}

.signup-form h1,
.login-form h1 {
  color: var(--primary-color);
  font-size: 1.8rem;
  padding-bottom: 0.4rem;
  font-family: var(--header-font);
}

.signup-form p,
.login-form p {
  font-size: 0.9rem;
  line-height: 1.5rem;
}

.form-input {
  padding-top: 2rem;
  position: relative;
}

form {
  margin-top: 1.5rem;
}

.form-input input {
  width: 100%;
  height: 3rem;
  border-radius: 5px;
  border: 2px solid var(--secondary-color);
  padding: 1rem;
  outline: none;
  font-size: 1rem;
}

.form-input input:focus {
  border: 1px solid var(--primary-color);
}
.form-input label {
  display: block;
  padding-bottom: 0.5rem;
  font-size: 0.85rem;
}
.login-btn {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 3rem;
  border-radius: 5px;
  outline: none;
  border: none;
  background-color: var(--primary-color);
  color: var(--white-color);
  font-size: 1rem;
  margin-top: 2rem;
}

.Dont-have-acc {
  padding-top: 1.5rem;
  color: var(--text-color);
  text-align: center;
}

.Dont-have-acc a {
  color: var(--primary-color);
  text-decoration: none;
  border: 1px solid var(--primary-color);
  display: block;
  width: 100%;
  padding: 0.7rem 0;
  margin-top: 0.3rem;
  border-radius: 5px;
}

.reset-pass-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 0.2rem;
}
.reset-pass-container a {
  text-decoration: none;
  color: var(--primary-color);
  font-size: 0.8rem;
}

#error {
  background-color: #d82148;
  padding: 0.5rem;
  text-align: center;
  color: var(--white-color);
  margin-top: 1rem;
  border-radius: 5px;
}

.login-form-container .spinner,
.signup-form-container .spinner {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #000;
  left: 0;
  bottom: 0;
  opacity: 0.5;
}
.pass-req {
  background-color: var(--secondary-color);
  padding: 0.5rem;
  margin-top: 1rem;
  border-radius: 5px;
}
.pass-req ul {
  list-style: inside;
}
.pass-req ul li {
  padding: 0.2rem 0;
}

/* reset password section */
.password-reset-container {
  padding: 1rem;
  color: var(--text-color);
}
.resetpassword {
  text-align: center;
  padding: 3rem 2rem;
  background-color: var(--white-color);
  box-shadow: 0px -4px 16px rgba(107, 124, 243, 0.06);
  border-radius: 5px;
}

.resetpassword p {
  color: var(--secondary-text-color);
  font-size: 0.85rem;
  padding-top: 0.5rem;
  line-height: 1.3rem;
}
.resetpassword .form-input label {
  text-align: left;
}

.reset-password-btn {
  border: none;
  background-color: var(--primary-color);
  color: var(--white-color);
  margin-top: 2rem;
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
}
.success {
  text-align: center;
}
.success img {
  width: 7rem;
  background-color: var(--secondary-color);
  border-radius: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
}

.password-toggle {
  position: absolute;
  right: 1rem;
  bottom: 0.6rem;
  cursor: pointer;
}

.password-toggle svg {
  font-size: 1.3rem;
}

@media only screen and (min-width: 768px) {
  .signup-form,
  .login-form,
  .password-reset-container {
    width: 750px;
    margin: 0 auto;
    margin-top: 1rem;
    padding: 2rem;
  }
}
