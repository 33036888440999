.main-container {
  margin-top: 4rem;
  padding: 1rem;
  font-size: 0.9rem;
}
.main-container h2 {
  font-size: 1.5rem;
  color: var(--text-color);
  font-family: var(--header-font);
  text-align: center;
}
.left-service {
  padding-top: 2rem;
}
.left-service br {
  display: none;
}
.left-service p {
  padding-bottom: 1rem;
  color: var(--secondary-text-color);
  line-height: 1.5rem;
}
.service-img {
  margin: 1rem 0;
}
.service-img img {
  width: 100%;
}
.services {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: center;
}
.services div {
  padding-left: 1rem;
}
.services h3 {
  font-size: 1.2rem;
  padding-top: 1rem;
  color: var(--text-color);
  font-family: var(--header-font);
}
.services p {
  padding-top: 0.5rem;
  color: var(--secondary-text-color);
  line-height: 1.3rem;
}
.services img {
  width: 3rem;
}

/* media queries */
@media only screen and (min-width: 879px) {
  .service-resp-container {
    padding: 0 2rem;
  }
  .main-container h2 {
    text-align: left;
    font-size: 2rem;
  }
  .service-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .left-service {
    padding-right: 1rem;
  }

  .right-service {
    padding-top: 1rem;
  }

  .services img {
    background-color: var(--white-color);
    width: 5rem;
    padding: 0.8rem;
    border-radius: 10px;
  }
}

@media only screen and (min-width: 1024px) {
  .left-service br {
    display: block;
  }
}

@media only screen and (min-width: 1440px) {
  .service-resp-container {
    width: 1390px;
    margin: 0 auto;
  }

  .service-img {
    width: 80%;
  }

  .services {
    padding-bottom: 2rem;
  }

  .services h3 {
    font-size: 2rem;
  }

  .main-container h2 {
    font-size: 2.7rem;
  }
}
