.why-choose-us {
  padding: 2.5rem 1rem;
}
.title {
  text-align: center;
  font-size: 1.4rem;
  font-family: var(--header-font);
  font-weight: 700;
  padding: 1rem 0;
  color: var(--text-color);
}
.content-container section {
  margin-top: 2rem;
  background-color: var(--white-color);
  padding: 1.5rem 1rem;
  border-radius: 10px;
}
.content-container section img {
  width: 3.5rem;
  margin-bottom: 1rem;
}
.content-container section h5 {
  font-size: 0.85rem;
  padding-bottom: 1rem;
  font-family: var(--header-font);
  color: var(--text-color);
}
.content-container section p {
  color: var(--secondary-text-color);
  font-size: 0.9rem;
  line-height: 1.5rem;
}

@media only screen and (min-width: 879px) {
  .why-choose-us {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .title {
    font-size: 2rem;
  }

  .content-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
  }
}

@media only screen and (min-width: 1024px) {
  .content-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (min-width: 1440px) {
  .choose-us-container {
    width: 1390px;
    margin: 0 auto;
    padding: 0 2rem;
  }
  .title {
    font-size: 2.7rem;
  }
}
